import React from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import {
  Container,
  ExploreMore,
  SectionPage,
  SectionTitle,
} from "../../components/Section"
import CustomBuildingForm from "../../components/CustomBuildingForm"
import PrimaryButton from "../../components/Button/PrimaryButton"

const SectionPages = styled(SectionPage)`
  position: relative;
  overflow: hidden;
  background-image: linear-gradient(
    0deg,
    rgba(112, 112, 112, 1) 0.1em,
    transparent 6.04px
  );
  background-size: 1em 1em;
  &:before {
    display: block;
    content: "";
    opacity: 0.98;
    width: 100%;
    height: 100%;
    background: #062c44;
    @media (min-width: 768px) {
      opacity: 0.98;
      background: linear-gradient(
        90deg,
        rgb(30, 65, 86) 0%,
        rgb(30, 65, 86) 50%,
        rgb(6, 44, 68) 50%,
        rgb(6, 44, 68) 100%
      );
    }
  }
`

const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -30px;
  @media (min-width: 768px) {
    margin: 0 -40px;
  }
`
const GridLeft = styled.div`
  position: relative;
  width: 100%;
  padding: 0 30px;
  @media (min-width: 768px) {
    padding: 0 40px;
    flex: 0 0 50%;
    max-width: 50%;
  }
`
const GridRight = styled.div`
  position: relative;
  width: 100%;
  padding: 0 30px;
  @media (min-width: 768px) {
    padding: 0 40px;
    flex: 0 0 50%;
    max-width: 50%;
  }
`
const CustomOR = styled.div`
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 1;
  border-radius: 50%;
  border: 2px solid #fff;
  color: #fff;
  font-weight: 700;
  font-family: Chakra Petch;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #1e4156;
  height: 40px;
  width: 40px;
  font-size: 18px;
  line-height: 28px;
  display: none;
  @media (min-width: 768px) {
    display: flex;
  }
  @media (min-width: 992px) {
    height: 60px;
    width: 60px;
    font-size: 30px;
    line-height: 40px;
  }
`
const SectionCta = styled.div`
  margin: 0 0 30px;
`
const SectionCtaBody = styled.div`
  padding: 0;
`
const House3D = styled.div`
  margin-bottom: 30px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  .gatsby-image-wrapper {
    max-width: 75%;
  }
`

const VisualSection = ({ location }) => {
  return (
    <SectionPages
      ept="80px"
      epb="80px"
      xpt="40px"
      xpb="40px"
      pt="30px"
      pb="30px"
    >
      <Container>
        <Grid>
          <GridLeft>
            <CustomBuildingForm location={location} />
          </GridLeft>
          <CustomOR>OR</CustomOR>
          <GridRight>
            <SectionCta>
              <SectionTitle color="#fff">
                You can use 3D Design Tool to Customize & Visualize your
                Building.
              </SectionTitle>
              <SectionCtaBody>
                <House3D>
                  <StaticImage
                    src="../../images/3d-house.png"
                    alt="3D Design Tool"
                  />
                </House3D>
                <ExploreMore>
                  <a
                    href="https://probuiltsteel.sensei3d.com/"
                    target="_blank"
                    rel="noreferrer"
                    className="btn-lg"
                  >
                    <PrimaryButton text="3D Estimator" />
                  </a>
                </ExploreMore>
              </SectionCtaBody>
            </SectionCta>
          </GridRight>
        </Grid>
      </Container>
    </SectionPages>
  )
}

export default VisualSection
