import React from 'react'
const StepPoint = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
	  <g id="Group_2998" data-name="Group 2998" transform="translate(-944 -6793)">
	    <circle id="Ellipse_52" data-name="Ellipse 52" cx="18" cy="18" r="18" transform="translate(944 6793)" fill="#fff"/>
	    <g id="Group_791" data-name="Group 791" transform="translate(34 132)">
	      <circle id="Ellipse_43" data-name="Ellipse 43" cx="12.857" cy="12.857" r="12.857" transform="translate(915.143 6666.146)" fill="#ed602b" opacity="0.2"/>
	      <circle id="Ellipse_50" data-name="Ellipse 50" cx="7.714" cy="7.714" r="7.714" transform="translate(920.286 6671.285)" fill="#ed602b" opacity="0.4"/>
	      <circle id="Ellipse_53" data-name="Ellipse 53" cx="3.571" cy="3.571" r="3.571" transform="translate(924.43 6675.431)" fill="#ed602b"/>
	    </g>
	  </g>
	</svg>
)

export default StepPoint
