import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { useStaticQuery, graphql, Link } from "gatsby"
import SecondaryButton from "../../components/Button/SecondaryButton"
import {
  SectionHeading,
  ExploreMore,
  SectionPage,
  Container,
  SectionDescription,
} from "../../components/Section"
import StepPoint from "../../components/Icons/StepPoint"
import TransParencyIcon from "../../components/Icons/TransParencyIcon"
import HandPointer from "../../components/Icons/HandPointer"
import ClockIcon from "../../components/Icons/ClockIcon"
import PlayButtonIcon from "../../components/Icons/PlayButtonIcon"

const MainPanel = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  @media (min-width: 992px) {
    margin: 60px 0 90px 0;
  }
`

const LeftPanel = styled.div`
  position: relative;
  width: 100%;
  @media (min-width: 992px) {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
`
const RightPanel = styled.div`
  position: relative;
  width: 100%;
  @media (min-width: 992px) {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
`

const Circle = styled.div`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  max-width: 430px;
  width: 100%;
  display: none;
  @media (min-width: 992px) {
    display: block;
  }
`
const PanelInfo = styled.div`
  background-color: #fff;
  position: relative;
  z-index: 1;
  width: 100%;
  @media (min-width: 992px) {
    padding: 20px 0;
    max-width: 600px;
  }
  @media (min-width: 1200px) {
    max-width: 760px;
  }
`

const UspGrid = styled.div`
  position: relative;
  z-index: 2;
  margin-bottom: 30px;
  @media (min-width: 992px) {
    margin: 0;
  }
`
const UspItemGrid = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  margin: 0 -5px;
  + .usp-item {
    margin-top: 30px;
  }
  @media (min-width: 992px) {
    .dots {
      position: relative;
    }
    &:first-child {
      .dots {
        left: -60px;
      }
    }
    &:nth-child(2) {
      .dots {
        left: -20px;
      }
    }
    &:last-child {
      .dots {
        left: -50px;
      }
    }
  }
`

const UspDot = styled.div`
  padding: 0 5px;
`
const UspIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 5px;
  > svg {
    width: 50px;
  }
`
const UspContent = styled.div`
  padding: 0 5px;
  h3 {
    color: #062c44;
    margin-bottom: 0;
  }
  p {
    margin-bottom: 0;
    font-size: 16px;
    line-height: 26px;
  }
`

const CirclBgImage = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "CircleBgImg.png" }) {
        childImageSharp {
          fluid(maxWidth: 420) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Img
      fluid={data.placeholderImage.childImageSharp.fluid}
      alt="Circle Background"
    />
  )
}
const SmallDepositSection = data => {
  return (
    <SectionPage
      ept="80px"
      epb="80px"
      xpt="60px"
      xpb="60px"
      pt="40px"
      pb="40px"
      bg="#fff"
    >
      <Container>
        <MainPanel>
          <LeftPanel>
            <PanelInfo>
              <SectionHeading textAlign="left">
                {data.data.title}
              </SectionHeading>
              <SectionDescription
                textAlign="left"
                dangerouslySetInnerHTML={{
                  __html: data.data.description.childMarkdownRemark.html,
                }}
              />
            </PanelInfo>
            <Circle>
              <CirclBgImage />
            </Circle>
          </LeftPanel>
          <RightPanel>
            <UspGrid className="usp-grid">
              <UspItemGrid className="usp-item">
                <UspDot className="dots">
                  <StepPoint />
                </UspDot>
                <UspIcon>
                  <ClockIcon />
                </UspIcon>
                <UspContent>
                  <h3>Quick</h3>
                  <p>Just apply by filling a simple form</p>
                </UspContent>
              </UspItemGrid>
              <UspItemGrid className="usp-item">
                <UspDot className="dots">
                  <StepPoint />
                </UspDot>
                <UspIcon>
                  <HandPointer />
                </UspIcon>
                <UspContent>
                  <h3>Easy</h3>
                  <p>Simply answer a few questions and sign the contract</p>
                </UspContent>
              </UspItemGrid>
              <UspItemGrid className="usp-item">
                <UspDot className="dots">
                  <StepPoint />
                </UspDot>
                <UspIcon>
                  <TransParencyIcon />
                </UspIcon>
                <UspContent>
                  <h3>Transparent</h3>
                  <p>
                    If approved, choose the available offer that suits you best.
                  </p>
                </UspContent>
              </UspItemGrid>
            </UspGrid>
          </RightPanel>
        </MainPanel>
        <ExploreMore>
          <Link to="/buying-guide/financing-process/" className="btn-lg">
            <SecondaryButton text="Watch Us in Action" icon={PlayButtonIcon} />
          </Link>
        </ExploreMore>
      </Container>
    </SectionPage>
  )
}

export default SmallDepositSection
