import React from 'react'

const TransParencyIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="62.667" height="62" viewBox="0 0 62.667 62">
	  <g id="transparency" transform="translate(-1 -1)">
	    <path id="Path_12599" data-name="Path 12599" d="M37,44V57a5,5,0,0,1-10,0V44Z" fill="#fff"/>
	    <path id="Path_12600" data-name="Path 12600" d="M37,40v4H27V40H37Z" fill="#fff"/>
	    <path id="Path_12601" data-name="Path 12601" d="M35,36v4H29V36h6Z" fill="#fff"/>
	    <path id="Path_12602" data-name="Path 12602" d="M19.03,29.98A16.944,16.944,0,0,0,32,36H1V30H19Z" fill="#fff"/>
	    <path id="Path_12603" data-name="Path 12603" d="M44.95,30H54V27l8,6-8,6V36H32a17.021,17.021,0,1,1,12.95-6Z" fill="#fff"/>
	    <circle id="Ellipse_54" data-name="Ellipse 54" cx="11" cy="11" r="11" transform="translate(21 8)" fill="#98dfff"/>
	    <path id="Path_12604" data-name="Path 12604" d="M63.667,33,53,25v4H46.939a17.992,17.992,0,1,0-29.895,0H5v2H18.622a18.088,18.088,0,0,0,5.17,4H1v2H28v2H26v4H24v2h2V57a6,6,0,1,0,12,0V45h2V43H38V39H36V37H53v4ZM36,57a4,4,0,0,1-8,0V45h8ZM28,43V41h8v2Zm6-4H30V37h4Zm-2-4a16.058,16.058,0,1,1,12.46-6H38.618A11.988,11.988,0,1,0,32,31H55V29l5.333,4L55,37V35Zm0-6A10,10,0,1,1,42,19,10.011,10.011,0,0,1,32,29Z" fill="#468e9f"/>
	    <path id="Path_12605" data-name="Path 12605" d="M30.215,13.27A6.011,6.011,0,0,1,32,13V11a7.994,7.994,0,0,0-2.378.36Z" fill="#02424c"/>
	    <path id="Path_12606" data-name="Path 12606" d="M28.833,13.9l-1.058-1.7A8,8,0,0,0,26.285,24.6l1.429-1.4a6,6,0,0,1,1.119-9.3Z" fill="#02424c"/>
	    <path id="Path_12607" data-name="Path 12607" d="M1,29H3v2H1Z"/>
	    <path id="Path_12608" data-name="Path 12608" d="M51.756,15.867l1.976-.312c-.1-.61-.219-1.221-.365-1.815l-1.942.478C51.558,14.757,51.669,15.312,51.756,15.867Z"/>
	    <path id="Path_12609" data-name="Path 12609" d="M52.85,11.962c-.2-.584-.421-1.165-.666-1.727l-1.834.8c.222.511.425,1.039.6,1.57Z" fill="#02424c"/>
	    <path id="Path_12610" data-name="Path 12610" d="M52,19v.219l2,.025V19q0-.812-.058-1.607l-1.995.145Q52,18.262,52,19Z" fill="#02424c"/>
	    <path id="Path_12611" data-name="Path 12611" d="M12,19.219V19q0-.738.053-1.462l-1.995-.145Q10,18.189,10,19v.244Z" fill="#02424c"/>
	    <path id="Path_12612" data-name="Path 12612" d="M12.575,14.218l-1.942-.478c-.146.594-.269,1.2-.365,1.815l1.976.312C12.331,15.312,12.442,14.757,12.575,14.218Z" fill="#02424c"/>
	    <path id="Path_12613" data-name="Path 12613" d="M13.649,11.033l-1.834-.8c-.244.562-.468,1.143-.666,1.727l1.9.641C13.224,12.072,13.428,11.543,13.649,11.033Z" fill="#02424c"/>
	  </g>
	</svg>
)

export default TransParencyIcon



