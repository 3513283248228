import React from 'react'
import ThirdStepBar from "../VerticalStepBar/ThirdStepBar"
import { StepGrid, StepLeftGrid, StepRightGrid, ControlLabel, CustomValidationMessage, RequiredTriangle, FormRow, FormTwoCol } from "../FormStyle"
import { Field } from '../Section'

class Step3 extends React.Component {
  render() {
    const { first_name, fNameRequire, fNameInputPattern, last_name, lNameRequire, lNameInputPattern, email, emailRequire, emailInputPattern, phone_no, phoneRequire, phoneInputPattern, zipcode, zipcodeRequire, zipCodeInputPattern } = this.props
    
    if (this.props.currentStep !== 3) { // Prop: The current step
      return null
    }
    // The markup for the Step 1 UI
    return(
      <StepGrid>
        <StepLeftGrid><ThirdStepBar fill="white" /></StepLeftGrid>
        <StepRightGrid>
            <ControlLabel color="#fff">Tell us your more information so our customer care executives can call you and describe our services in a better way.</ControlLabel>
            <FormRow>
              <FormTwoCol>
                <Field>
                  <input type="text" name="first_name" placeholder="Your First Name" onChange={this.props.handleChange} value={first_name} pattern="[A-Za-z\s]+$" className="form-control form-outline form-primary form-rounded" />
                <CustomValidationMessage display={fNameInputPattern ?"block": "none"}><RequiredTriangle /><span>{fNameInputPattern}</span></CustomValidationMessage>
                <CustomValidationMessage display={fNameRequire ?"block": "none"}><RequiredTriangle /><span>Please input this field</span></CustomValidationMessage>
                </Field>
              </FormTwoCol>
              <FormTwoCol>
                <Field>
                  <input type="text" name="last_name" placeholder="Your Last Name" onChange={this.props.handleChange} value={last_name} pattern="[A-Za-z\s]+$" className="form-control form-outline form-primary form-rounded" />
                  <CustomValidationMessage display={lNameInputPattern ?"block": "none"}><RequiredTriangle /><span>{lNameInputPattern}</span></CustomValidationMessage>
                  <CustomValidationMessage display={lNameRequire ?"block": "none"}><RequiredTriangle /><span>Please input this field</span></CustomValidationMessage>
                </Field>
              </FormTwoCol>
              <FormTwoCol>
                <Field>
                  <input type="email" name="email" placeholder="Your Email" onChange={this.props.handleChange} value={email} pattern="^[^\s@]+@[^\s@]+\.[^\s@]+$" className="form-control form-outline form-primary form-rounded" />
                  <CustomValidationMessage display={emailInputPattern ?"block": "none"}><RequiredTriangle /><span>{emailInputPattern}</span></CustomValidationMessage>
                  <CustomValidationMessage display={emailRequire ?"block": "none"}><RequiredTriangle /><span>Please input this field</span></CustomValidationMessage>
                  </Field>
              </FormTwoCol>
              <FormTwoCol>
                <Field>
                  <input name="phone_no" placeholder="Phone Number" onChange={this.props.handleChange} value={phone_no} pattern="^[0-9]{10}$" className="form-control form-outline form-primary form-rounded" />
                  <CustomValidationMessage display={phoneInputPattern ?"block": "none"}><RequiredTriangle /><span>{phoneInputPattern}</span></CustomValidationMessage>
                  <CustomValidationMessage display={phoneRequire ?"block": "none"}><RequiredTriangle /><span>Please input this field</span></CustomValidationMessage>
                </Field>
              </FormTwoCol>
              <FormTwoCol>
                <Field>
                  <input name="zipcode" placeholder="Zip Code" onChange={this.props.handleChange} value={zipcode} pattern="^[0-9]{5}$" className="form-control form-outline form-primary form-rounded" />
                  <CustomValidationMessage display={zipCodeInputPattern ?"block": "none"}><RequiredTriangle /><span>{zipCodeInputPattern}</span></CustomValidationMessage>
                  <CustomValidationMessage display={zipcodeRequire ?"block": "none"}><RequiredTriangle /><span>Please input this field</span></CustomValidationMessage>
                  </Field>
              </FormTwoCol>
            </FormRow>
        </StepRightGrid>
      </StepGrid>
    )
  }
}

export default Step3
