import React from 'react'

const PlayButtonIcon = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="18" height="22" viewBox="0 0 18 22">
	  <g id="play-button-arrowhead" transform="translate(-8.107)">
	    <path id="Path_15187" data-name="Path 15187" d="M11.368.485C9.567-.653,8.107.279,8.107,2.565V19.433c0,2.288,1.46,3.219,3.261,2.082L24.756,13.06c1.8-1.138,1.8-2.982,0-4.12Z" transform="translate(0 0)" fill={props.fill}/>
	  </g>
	</svg>
)

export default PlayButtonIcon