import React, { Component } from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { Link } from "gatsby"
import Slider from "react-slick"

import { SectionPage, Container, SectionHeading, SectionDescription, ExploreMore,  } from "../Section"
import PrimaryButton from "../Button/PrimaryButton"
import TimeBackground from "../../images/creat-time.png"
import ArrowIcon from "../Icons/ArrowIcon"
import LeftArrowIcon from "../Icons/LeftArrowIcon"

const SliderContent = styled.div`
  padding:0 0 20px;
  .slick-list{
    margin:0 -15px;
  }
  & ul {
    position: relative !important;
    transform: none !important;
    width: 100% !important;
    top: 0 !important;
    right: 0 !important;
  }
`
const ArticleCard = styled.div`
  padding:0 15px;
`
const ArticleTitle = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: #062c44;
  &:hover,
  &:focus {
    color: #ed602b;
  }
`
const ArticleCardImage = styled.div`
  position:relative;

`
const ArticleCardBody = styled.div`
  padding:0;
`
const ArticleDesc = styled.p`
  text-align: left;
  font-size: 18px;
  line-height: 28px;
  color: #010920;
  margin-bottom:0;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`

const CreatTime = styled.div`
  position: relative;
  z-index: 20;
  width: fit-content;
  background: url(${props => props.bg}) top right;
  background-size: cover;
  padding: 5px 15px 5px 10px;
  margin-top: -15px;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  color: #fff;
`
const RightArrow = styled.div`
  background: #1e4156;
  position: absolute;
  z-index: 10;
  top: 25%;
  transform: translateY(-25%);
  right: 0px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 34px;
  height: 34px;
  & svg {
    width:20px;
  }
  @media (min-width:992px) {
    width: 44px;
    height: 44px;
    & svg {
      width:24px;
    }
  }
  & :hover {
    background: #EC602D;
    & svg {
      transition: all 0.3s ease;
      transform: translateX(5px);
    }
  }
`
const LeftArrow = styled.div`
  background: #1e4156;
  position: absolute;
  z-index: 10;
  top: 25%;
  transform: translateY(-25%);
  left: 0px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 34px;
  height: 34px;
  & svg {
    width:20px;
  }
  @media (min-width:992px) {
    width: 44px;
    height: 44px;
    & svg {
      width:24px;
    }
  }
  & :hover {
    background: #EC602D;
    & svg {
      transition: all 0.3s ease;
      transform: translateX(-5px);
    }
  }
`

function SampleNextArrow(props) {
  const { className, style } = props
  return <div className={className} style={{ ...style, display: "none" }} />
}

function SamplePrevArrow(props) {
  const { className, style } = props
  return <div className={className} style={{ ...style, display: "none" }} />
}

class ArticleCarousel extends Component {
  constructor(props) {
    super(props)
    this.renderSliderContent = this.renderSliderContent.bind(this)
    this.next = this.next.bind(this)
    this.previous = this.previous.bind(this)
  }
  next() {
    this.slider.slickNext()
  }
  previous() {
    this.slider.slickPrev()
  }
  renderSliderContent() {
    const { data } = this.props
    return data.edges.map((item, i) => {
      const temp = item.node.title
        .replace(/[^\w\s]+/gi, " ")
        .replace(/  +/g, " ")
      const titleUrl = temp
        .split(" ")
        .join("-")
        .toLowerCase()

      return (
        <ArticleCard key={i}>
          <Link to={`/blog/${titleUrl}/`}>
            <ArticleCardImage>
              <Img fluid={item.node.tumbnail.fluid} alt={`thumbnail-${item.node.title}`} />
              <CreatTime bg={TimeBackground}>{item.node.createdAt}</CreatTime>
            </ArticleCardImage>
            <ArticleCardBody>
              <ArticleTitle className="h3">{item.node.title}</ArticleTitle>
              <ArticleDesc>{item.node.description?.description}</ArticleDesc>
            </ArticleCardBody>
          </Link>
        </ArticleCard>
      )
    })
  }

  render() {
    const { sectionData } = this.props
    const settings = {
      centerMode: false,
      infinite: true,
      autoPlay: true,
      slidesToShow: 3,
      speed: 500,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      adaptiveHeight: true,
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 768,
          settings: {
            className: "center",
            centerPadding: "0px",
            centerMode: true,
            slidesToShow: 1,
            dots: false,
          },
        },
      ],
    }
    return (
      <SectionPage
        ept="80px"
        epb="80px"
        xpt="60px"
        xpb="60px"
        pt="40px"
        pb="40px"
        bg="#F4FBFF"
      >
        <SectionHeading textAlign="center">{sectionData.title}</SectionHeading>
        <SectionDescription
          mb="15px"
          dangerouslySetInnerHTML={{
            __html: sectionData.description.childMarkdownRemark.html,
          }}
        />
        <Container>
          <SliderContent>
            <LeftArrow onClick={this.previous}><LeftArrowIcon fill="white" /></LeftArrow>
            <RightArrow onClick={this.next}><ArrowIcon fill="white" /></RightArrow>
            <Slider {...settings} ref={c => (this.slider = c)}>{this.renderSliderContent()}</Slider>
          </SliderContent>
          <ExploreMore>
            <Link to="/blog/" className="btn-lg">
              <PrimaryButton text="Explore All Articles" />
            </Link>
          </ExploreMore>
        </Container>
      </SectionPage>
    )
  }
}

export default ArticleCarousel
