import React from 'react'

const ClockIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="58" height="55.675" viewBox="0 0 58 55.675">
	  <g id="XMLID_806_" transform="translate(0 -10.262)">
	    <g id="XMLID_1434_" transform="translate(13.368 11.395)">
	      <rect id="XMLID_1457_" width="7.75" height="4.472" transform="translate(17.858 5.438)" fill="#fff"/>
	      <path id="XMLID_1456_" d="M252.567,25.7h8.156a2.719,2.719,0,0,0,2.719-2.719h0a2.719,2.719,0,0,0-2.719-2.719h-8.156a2.719,2.719,0,0,0-2.719,2.719h0A2.719,2.719,0,0,0,252.567,25.7Z" transform="translate(-234.912 -20.262)" fill="none"/>
	      <circle id="XMLID_1452_" cx="21.75" cy="21.75" r="21.75" transform="translate(0 9.91)" fill="#fff"/>
	      <circle id="XMLID_2618_" cx="16.441" cy="16.441" r="16.441" transform="translate(5.31 15.219)" fill="#98dfff"/>
	      <circle id="XMLID_2592_" cx="2.387" cy="2.387" r="2.387" transform="translate(19.363 29.273)" fill="#fff"/>
	    </g>
	    <g id="XMLID_386_" transform="translate(0 10.262)">
	      <path id="XMLID_389_" d="M57.888,40.779a22.864,22.864,0,0,0-17.78-20.049V17.851a3.852,3.852,0,0,0-.93-7.589H31.022a3.852,3.852,0,0,0-.93,7.589v2.886a22.746,22.746,0,0,0-11.156,6.136,22.993,22.993,0,0,0-1.923,2.183c-.028,0-.057,0-.086,0H8.855a1.133,1.133,0,0,0,0,2.266h6.617a22.709,22.709,0,0,0-2.01,4.354H1.133a1.133,1.133,0,1,0,0,2.266H12.822a23.033,23.033,0,0,0-.562,4.354H7.25a1.133,1.133,0,1,0,0,2.266H12.3A23,23,0,0,0,13,48.912H5.664a1.133,1.133,0,0,0,0,2.266h8.062a22.88,22.88,0,0,0,19.13,14.649q1.123.111,2.245.11a22.938,22.938,0,0,0,12.488-3.691,1.133,1.133,0,0,0-1.236-1.9,20.617,20.617,0,1,1,6.072-6.08,1.133,1.133,0,1,0,1.9,1.234A22.956,22.956,0,0,0,57.888,40.779ZM29.436,14.114a1.588,1.588,0,0,1,1.586-1.586h8.156a1.586,1.586,0,0,1,0,3.172H31.022A1.588,1.588,0,0,1,29.436,14.114Zm2.922,6.234V17.965h5.484v2.379A23.082,23.082,0,0,0,32.358,20.348Z" transform="translate(0 -10.262)" fill="#468e9f"/>
	      <path id="XMLID_391_" d="M436.9,425.5a1.133,1.133,0,1,0,.8.332A1.139,1.139,0,0,0,436.9,425.5Z" transform="translate(-386.405 -378.461)" fill="#468e9f"/>
	      <path id="XMLID_394_" d="M172.445,144.609a17.573,17.573,0,1,0,17.573,17.574A17.593,17.593,0,0,0,172.445,144.609Zm0,32.881a15.308,15.308,0,1,1,15.308-15.308A15.325,15.325,0,0,1,172.445,177.49Z" transform="translate(-137.328 -129.39)" fill="#468e9f"/>
	      <path id="XMLID_397_" d="M270.2,219.743l-5.611,5.611a3.511,3.511,0,0,0-3.11,0l-2.511-2.511a1.133,1.133,0,1,0-1.6,1.6l2.511,2.511a3.52,3.52,0,1,0,6.314,0l5.446-5.446h0l.165-.165a1.133,1.133,0,1,0-1.6-1.6Zm-7.166,10.022a1.254,1.254,0,1,1,1.255-1.255A1.256,1.256,0,0,1,263.036,229.765Z" transform="translate(-227.918 -195.718)" fill="#104d56"/>
	      <path id="XMLID_398_" d="M399.837,289.86h-1.05a1.133,1.133,0,1,0,0,2.266h1.05a1.133,1.133,0,0,0,0-2.266Z" transform="translate(-352.607 -258.186)" fill="#02424c"/>
	      <path id="XMLID_399_" d="M195.264,289.616h-1.049a1.133,1.133,0,1,0,0,2.266h1.049a1.133,1.133,0,1,0,0-2.266Z" transform="translate(-171.209 -257.97)" fill="#02424c"/>
	      <path id="XMLID_400_" d="M301.256,186.134A1.133,1.133,0,0,0,302.389,185v-1.049a1.133,1.133,0,0,0-2.266,0V185A1.133,1.133,0,0,0,301.256,186.134Z" transform="translate(-266.124 -163.271)" fill="#02424c"/>
	      <path id="XMLID_424_" d="M301.012,387.393a1.133,1.133,0,0,0-1.133,1.133v1.049a1.133,1.133,0,0,0,2.266,0v-1.049A1.133,1.133,0,0,0,301.012,387.393Z" transform="translate(-265.908 -344.67)" fill="#02424c"/>
	      <path id="XMLID_425_" d="M1.133,351.44a1.133,1.133,0,1,0,.8,1.934,1.133,1.133,0,0,0-.8-1.934Z" transform="translate(0 -312.79)" fill="#468e9f"/>
	    </g>
	  </g>
	</svg>
)

export default ClockIcon