import React from 'react'
import styled from 'styled-components'


const BaseButton = styled.div`
	width: ${props=>props.width};
	position: relative;
	height: 32px;
	color: white;
	background: url(${props=>props.bg}) center center;
	background-size: cover;
	text-align: left;
	display: inline-block;
	vertical-align: middle;
	margin-bottom: ${props=>props.mb};
	z-index: 8;
`
const BlurPart = styled.div`
	position: relative;
	height: 32px;
	width: 40%;
	background: rgba(0,0,0,0.3);
	display: inline-block;
`
const Bdr = styled.div`
  display: inline-block;
  border-top: 32px solid rgba(0,0,0,0.3);
  border-right: 25px solid transparent;
`

const ButtonText = styled.div`
	position: relative;
	height: 32px;
	z-index: 10;
	text-align: center;
	margin-top: ${props=>props.mt};
	font-family: Chakra Petch;
	font-size: ${props=>props.fSize};
	padding-right: ${props=>props.pr};
	font-weight: bold;
`
ButtonText.defaultProps = {
	mt: "-32px"
}

class PriceTip extends React.Component {
	render(){
		const { text, bgImg, width, pr, mb, mt, fSize } = this.props
		return(
			<BaseButton bg={bgImg} width={width} mb={ mb }>
				<div>
					<BlurPart />
					<Bdr />
				</div>
				<ButtonText pr={pr} mt={mt} fSize={fSize}>
					{text}
				</ButtonText>
			</BaseButton>
		)
	}
}

export default PriceTip
