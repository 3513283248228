import React from 'react'
import WidthIcon from "../Icons/WidthIcon"
import LengthIcon from "../Icons/LengthIcon"
import HeightIcon from "../Icons/HeightIcon"
import SecondStepBar from "../VerticalStepBar/SecondStepBar"
import { Dimensions, DimensionItem, DimensionSymbol, FormCheckGrid, FormCheckItmGrid, ControlLabel, StepGrid, StepLeftGrid, StepRightGrid} from "../FormStyle"
import { Field, Radio, FormListCheck  } from '../Section'

class Step2 extends React.Component {
  render() {
    if (this.props.currentStep !== 2) { // Prop: The current step
      return null
    }
    // The markup for the Step 1 UI
    return(
      <StepGrid>
        <StepLeftGrid><SecondStepBar fill="white" /></StepLeftGrid>
        <StepRightGrid>
            <ControlLabel color="#fff">Choose the Dimensions</ControlLabel>

            <Dimensions>
              <DimensionItem>
                <DimensionSymbol className='demension'>
                  <span className='icon'><WidthIcon /></span>
                  <span className='text'>Width</span>
                </DimensionSymbol>
                <Field><input type="number" name="width" onChange={this.props.handleChange} value={this.props.width}  className="form-control form-outline form-primary form-rounded" /></Field>
              </DimensionItem>

              <DimensionItem>
                <DimensionSymbol className='demension'>
                  <span className='icon'><LengthIcon /></span>
                  <span className='text'>Length</span>
                </DimensionSymbol>
                <Field><input type="number" name="length" onChange={this.props.handleChange} value={this.props.length} className="form-control form-outline form-primary form-rounded" /></Field>
              </DimensionItem>

              <DimensionItem> 
                <DimensionSymbol className='demension'>
                  <span className='icon'><HeightIcon /></span>
                  <span className='text'>Height</span>
                </DimensionSymbol>
                <Field><input type="number" name="height" onChange={this.props.handleChange} value={this.props.height}  className="form-control form-outline form-primary form-rounded" /></Field>
              </DimensionItem>
            </Dimensions>

            <FormCheckGrid>

              <FormCheckItmGrid>
                <ControlLabel color="#fff">Back Ends</ControlLabel>
                <FormListCheck>
                  <Radio className='radio' color='#fff'><label><input type="radio" className="form-control" name="back_end" value="Open" onChange={this.props.handleChange}/>Open </label></Radio>
                  <Radio className='radio' color='#fff'><label><input type="radio" className="form-control" name="back_end" value="Fully Enclose" onChange={this.props.handleChange}/>Fully Enclose</label></Radio>
                  <Radio className='radio' color='#fff'><label><input type="radio" className="form-control" name="back_end" value="Gable Ends" onChange={this.props.handleChange}/>Gable Ends</label></Radio>
                </FormListCheck>
              </FormCheckItmGrid>

              <FormCheckItmGrid>
                <ControlLabel color="#fff">Front Ends</ControlLabel>
                <FormListCheck>
                  <Radio className='radio' color='#fff'><label><input type="radio" className="form-control" name="front_end" value="Open" onChange={this.props.handleChange}/>Open </label></Radio>
                  <Radio className='radio' color='#fff'><label><input type="radio" className="form-control" name="front_end" value="Fully Enclose" onChange={this.props.handleChange}/>Fully Enclose</label></Radio>
                  <Radio className='radio' color='#fff'><label><input type="radio" className="form-control" name="front_end" value="Gable Ends" onChange={this.props.handleChange}/>Gable Ends</label></Radio>
                </FormListCheck>
              </FormCheckItmGrid>

              <FormCheckItmGrid>
                <ControlLabel color="#fff">Left Side</ControlLabel>
                <FormListCheck>
                  <Radio className='radio' color='#fff'><label><input type="radio" className="form-control" name="left_side" value="Open" onChange={this.props.handleChange}/>Open </label></Radio>
                  <Radio className='radio' color='#fff'><label><input type="radio" className="form-control" name="left_side" value="Fully Enclose" onChange={this.props.handleChange}/>Fully Enclose</label></Radio>
                  <Radio className='radio' color='#fff'><label><input type="radio" className="form-control" name="left_side" value="Gable Ends" onChange={this.props.handleChange}/>Gable Ends</label></Radio>
                </FormListCheck>
              </FormCheckItmGrid>

              <FormCheckItmGrid>
                <ControlLabel color="#fff">Right Side</ControlLabel>
                <FormListCheck>
                  <Radio className='radio' color='#fff'><label><input type="radio" className="form-control" name="right_side" value="Open" onChange={this.props.handleChange}/>Open </label></Radio>
                  <Radio className='radio' color='#fff'><label><input type="radio" className="form-control" name="right_side" value="Fully Enclose" onChange={this.props.handleChange}/>Fully Enclose</label></Radio>
                  <Radio className='radio' color='#fff'><label><input type="radio" className="form-control" name="right_side" value="Gable Ends" onChange={this.props.handleChange}/>Gable Ends</label></Radio>
                </FormListCheck>
              </FormCheckItmGrid>
            </FormCheckGrid>
        </StepRightGrid>
      </StepGrid>
    )
  }
}

export default Step2