import React from 'react'

const HandPointer = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="40" height="62.617" viewBox="0 0 40 62.617">
	  <g id="tap" transform="translate(-0.079 6.999)">
	    <g id="Ellipse_55" data-name="Ellipse 55" transform="translate(5.686 -6.999)" fill="#98dfff" stroke="#468e9f" strokeWidth="2">
	      <circle cx="13.551" cy="13.551" r="13.551" stroke="none"/>
	      <circle cx="13.551" cy="13.551" r="12.551" fill="none"/>
	    </g>
	    <path id="Path_12614" data-name="Path 12614" d="M131.043,15.228a6.512,6.512,0,0,1-3.737,5.9H121.7a6.54,6.54,0,0,1,1.4-12.3,6.864,6.864,0,0,1,1.4-.15,6.54,6.54,0,0,1,6.54,6.54Zm0,0" transform="translate(-104.979 -8.194)" fill="#fff"/>
	    <path id="Path_12616" data-name="Path 12616" d="M46.62,64.471v14.79s0,5.036-3.186,7.951a1.792,1.792,0,0,0-.551,1.327v2.868a1.874,1.874,0,0,1-1.869,1.869H26.065A1.874,1.874,0,0,1,24.2,91.407v-.346a5.556,5.556,0,0,0-1.673-3.98c-1.8-1.757-4.587-4.793-5.8-7.82-1.869-4.671-5.606-11.211-7.474-13.08s0-3.737,1.869-3.737,5.606,3.737,7.474,6.54a14.153,14.153,0,0,0,2.074,2.476,1.862,1.862,0,0,0,3.037-.785,11.191,11.191,0,0,0,.5-3.56V45.626a2.772,2.772,0,0,1,1.869-2.635A2.581,2.581,0,0,1,27,42.824a2.8,2.8,0,0,1,2.8,2.8V56.838A2.774,2.774,0,0,1,31.671,54.2a2.591,2.591,0,0,1,.934-.168,2.8,2.8,0,0,1,2.8,2.8v2.8a2.8,2.8,0,0,1,.822-1.981,2.653,2.653,0,0,1,1.047-.654,2.584,2.584,0,0,1,.934-.168,2.8,2.8,0,0,1,2.8,2.8v4.672a2.8,2.8,0,0,1,.822-1.981,2.657,2.657,0,0,1,1.046-.654,2.715,2.715,0,0,1,1.14-.159A2.906,2.906,0,0,1,46.62,64.471Zm0,0" transform="translate(-7.476 -38.592)" fill="#fff"/>
	    <path id="Path_12619" data-name="Path 12619" d="M.265,25.588a2.925,2.925,0,0,0,.846,3.258C2.9,30.63,6.563,37.073,8.379,41.613c1.239,3.1,3.96,6.136,6.024,8.141a4.638,4.638,0,0,1,1.384,3.316v.342a2.8,2.8,0,0,0,2.8,2.8H33.538a2.8,2.8,0,0,0,2.8-2.8v-2.87a.857.857,0,0,1,.249-.636c3.448-3.153,3.488-8.417,3.488-8.64V26.474a3.837,3.837,0,0,0-3.465-3.886,3.735,3.735,0,0,0-2.141.509V21.645a3.716,3.716,0,0,0-5.646-3.2,3.693,3.693,0,0,0-5.566-2.824V14.077A7.381,7.381,0,0,0,27,7.631a7.474,7.474,0,0,0-14.949,0,7.381,7.381,0,0,0,3.737,6.447V29.119a10.337,10.337,0,0,1-.445,3.258.944.944,0,0,1-.674.628.9.9,0,0,1-.841-.221A13.28,13.28,0,0,1,11.9,30.469c-1.621-2.425-5.7-6.956-8.255-6.956A3.66,3.66,0,0,0,.265,25.588ZM13.918,7.631a5.606,5.606,0,1,1,11.211,0,5.549,5.549,0,0,1-1.869,4.184V7.631a3.737,3.737,0,1,0-7.474,0v4.184A5.551,5.551,0,0,1,13.918,7.631Zm-3.58,23.876a15.18,15.18,0,0,0,2.208,2.639,2.764,2.764,0,0,0,2.577.672,2.816,2.816,0,0,0,1.99-1.842,12.169,12.169,0,0,0,.543-3.856V7.631a1.869,1.869,0,1,1,3.737,0v19.62a.934.934,0,1,0,1.869,0V18.842a1.869,1.869,0,0,1,3.738,0v8.408a.934.934,0,1,0,1.869,0V21.645a1.869,1.869,0,1,1,3.737,0v6.54a.934.934,0,0,0,1.869,0V26.317A1.853,1.853,0,0,1,35.021,25a1.891,1.891,0,0,1,1.458-.544,1.99,1.99,0,0,1,1.731,2.022V41.265c0,.046-.047,4.672-2.881,7.262a2.726,2.726,0,0,0-.856,2.014v2.87a.934.934,0,0,1-.934.935H18.59a.934.934,0,0,1-.934-.935v-.342A6.521,6.521,0,0,0,15.7,48.413c-1.583-1.537-4.414-4.556-5.589-7.495-1.923-4.8-5.727-11.439-7.682-13.393-.445-.444-.594-.856-.442-1.222a1.842,1.842,0,0,1,1.65-.92C4.911,25.382,8.353,28.53,10.338,31.507Zm0,0" transform="translate(0 -0.596)" fill="#468e9f"/>
	    <path id="Path_12620" data-name="Path 12620" d="M315.145,306.3a.934.934,0,0,0,.935-.935v-5.606a.934.934,0,0,0-1.869,0v5.606A.934.934,0,0,0,315.145,306.3Zm0,0" transform="translate(-279.738 -266.564)" fill="#02424c"/>
	    <path id="Path_12621" data-name="Path 12621" d="M306.613,388a.933.933,0,0,0,.66-.274,3.793,3.793,0,0,0,1.208-2.529.923.923,0,0,0-.912-.921.94.94,0,0,0-.957.9,2.056,2.056,0,0,1-.661,1.23.934.934,0,0,0,.661,1.595Zm0,0" transform="translate(-272.14 -342.659)" fill="#02424c"/>
	    <path id="Path_12622" data-name="Path 12622" d="M178.61,462.826h.934a.934.934,0,1,0,0-1.869h-.934a.934.934,0,1,0,0,1.869Zm0,0" transform="translate(-158.152 -410.945)" fill="#02424c"/>
	    <path id="Path_12623" data-name="Path 12623" d="M221.278,462.826h6.54a.934.934,0,1,0,0-1.869h-6.54a.934.934,0,0,0,0,1.869Zm0,0" transform="translate(-196.148 -410.945)" fill="#02424c"/>
	  </g>
	</svg>

)

export default HandPointer