import React from "react"
import FirstStepBar from "../VerticalStepBar/FirstStepBar"
import {ListBadge,Label,CustomValidationMessage,RequiredTriangle,ControlLabel, StepGrid, StepLeftGrid, StepRightGrid} from "../FormStyle"
import {Field} from "../../components/Section"


class Step1 extends React.Component {
  render() {
    const { buildingType, stateRequire, state, stateInputPattern } = this.props

    if (this.props.currentStep !== 1) {
      // Prop: The current step
      return null
    }
    // The markup for the Step 1 UI
    return (
      <StepGrid>
        <StepLeftGrid><FirstStepBar fill="white" /></StepLeftGrid>
        <StepRightGrid>
          <Field>
            <ControlLabel color="#fff">Choose Your Installation State</ControlLabel>
            <div>
              <input
                name="state"
                id="state"
                placeholder="Your State Zip Code"
                onChange={this.props.handleChange}
                pattern="^[0-9]{5}$"
                aria-label="state zip code"
                value={state}
                required
                className="form-control form-outline form-primary form-rounded"
              />
              <CustomValidationMessage display={stateInputPattern === "" ? "none" : "block"} width="fit-content">
                <RequiredTriangle /> <span>{stateInputPattern}</span>
              </CustomValidationMessage>
              <CustomValidationMessage display={stateRequire ? "block" : "none"}><RequiredTriangle /><span>Please input this field</span></CustomValidationMessage>
            </div>
          </Field>
          <Field>
            <ControlLabel color="#fff">Choose Your Building Type</ControlLabel>
            <ListBadge>
              <Label
              className="badge"
                bg={buildingType === "Carports" ? "#FF3E00" : "none"}
                borderColor={buildingType === "Carports" ? "#FF3E00" : "rgba(255,255,255,0.2)"}
                color={buildingType === "Carports" ? "#fff" : "rgba(255,255,255,0.2)"}
              >
                <input
                  type="radio"
                  name="buildingType"
                  value="Carports"
                  onChange={this.props.handleChange}
                />
                Carports
              </Label>
              <Label
              className="badge"
                bg={buildingType === "Garages" ? "#FF3E00" : "none"}
                borderColor={buildingType === "Garages" ? "#FF3E00" : "rgba(255,255,255,0.2)"}
                color={buildingType === "Garages" ? "#fff" : "rgba(255,255,255,0.2)"}
              >
                <input
                  type="radio"
                  name="buildingType"
                  value="Garages"
                  onChange={this.props.handleChange}
                />
                Garages
              </Label>
              <Label
              className="badge"
                bg={buildingType === "RV Covers" ? "#FF3E00" : "none"}
                borderColor={buildingType === "RV Covers" ? "#FF3E00" : "rgba(255,255,255,0.2)"}
                color={buildingType === "RV Covers" ? "#fff" : "rgba(255,255,255,0.2)"}
              >
                <input
                  type="radio"
                  name="buildingType"
                  value="RV Covers"
                  onChange={this.props.handleChange}
                />
                RV Covers
              </Label>
              <Label
              className="badge"
                bg={buildingType === "Barns" ? "#FF3E00" : "none"}
                borderColor={buildingType === "Barns" ? "#FF3E00" : "rgba(255,255,255,0.2)"}
                color={buildingType === "Barns" ? "#fff" : "rgba(255,255,255,0.2)"}
              >
                <input
                  type="radio"
                  name="buildingType"
                  value="Barns"
                  onChange={this.props.handleChange}
                />
                Barns
              </Label>
              <Label
              className="badge"
                bg={buildingType === "Utility Buildings" ? "#FF3E00" : "none"}
                borderColor={
                  buildingType === "Utility Buildings" ? "#FF3E00" : "rgba(255,255,255,0.2)"
                }
                color={buildingType === "Utility Buildings" ? "#fff" : "rgba(255,255,255,0.2)"}
              >
                <input
                  type="radio"
                  name="buildingType"
                  value="Utility Buildings"
                  onChange={this.props.handleChange}
                />
                Utility Buildings
              </Label>
              <Label
              className="badge"
                bg={buildingType === "Commercial Buildings" ? "#FF3E00" : "none"}
                borderColor={
                  buildingType === "Commercial Buildings" ? "#FF3E00" : "rgba(255,255,255,0.2)"
                }
                color={
                  buildingType === "Commercial Buildings" ? "#fff" : "rgba(255,255,255,0.2)"
                }
              >
                <input
                  type="radio"
                  name="buildingType"
                  value="Commercial Buildings"
                  onChange={this.props.handleChange}
                />
                Commercial Buildings
              </Label>
            </ListBadge>
          </Field>
        </StepRightGrid>
      </StepGrid>
      
    )
  }
}

export default Step1
