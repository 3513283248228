import styled from 'styled-components'

export const TwoColumns = styled.div`
	display: grid;
	margin-top: ${props=>props.mt};
	align-items: ${props=>props.alignItems};
	grid-template-columns: repeat(2, ${props=>props.gridCol});
	grid-template-row: auto;
	grid-column-gap: ${props=>props.colGap};
	grid-row-gap: ${props=>props.rowGap};

	@media(max-width: 768px){
		grid-template-columns: repeat(1, 1fr);
	}
`

TwoColumns.defaultProps = {
	alignItems: "center",
	gridCol: "1fr",
	colGap: "0px"
}