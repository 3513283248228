import React, { Component } from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import LazyLoad from "react-lazyload"
import ProductTip from "../../images/product-tip.png"
import PriceTip from "../Button/PriceTip"
import { TwoColumns } from "../Grid"
import ArrowIcon from "../Icons/ArrowIcon"

const Wrapper = styled.div`
  display: flex;
  margin-top: 30px;
  overflow: hidden;
  @media (max-width: 1000px) {
    display: block;
  }
`
const ImageWrapper = styled.div`
  position: relative;
  background: url(${props => props.bg}) center center;
  background-size: cover;
  width: ${props => props.width};
  height: 40vw;
  @media (max-width: 1000px) {
    width: 100%;
    height: ${props => props.height};
  }
  transition: ease 0.1s;
`
const TextWrap = styled.div`
  position: absolute;
  bottom: 0;
  padding: 55px 36px 30px;
  width: ${props => props.width};
  height: ${props => props.height};
  opacity: ${props => props.opacity};
  transition: all ease 0.5s;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.01), rgba(0, 0, 0, 1));
  & h3 {
    margin-bottom: 10px;
    color: white;
  }
  & p {
    text-align: left !important;
    color: white !important;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }
  & a {
    margin-top: 40px;
    width: 113px;
    color: white;
    text-decoration: none;
    &:hover {
      cursor: pointer;
      & svg {
        margin-left: 10px;
        transition: ease 0.3s;
      }
    }
  }
  @media (max-width: 1000px) {
    & a {
      margin-top: 20px;
    }
  }
  @media (max-width: 768px) {
    & h3 {
      margin-top: -20px;
    }
  }
`
const VerticalTextWrap = styled.h3`
  position: relative;
  transform: rotate(180deg);
  writing-mode: tb-rl;
  margin: auto;
  text-align: center;
  color: white;
  width: 100%;
  height: 40vw;
  background: #062c44;
  opacity: 0.8;
  padding-right: 40%;
  border-right: 1px solid;

  &:hover {
    cursor: pointer;
  }
  display: ${props => props.display};
  transition: ease 0.5s;

  @media (max-width: 1000px) {
    writing-mode: none;
    writing-mode: horizontal-tb;
    transform: rotate(0deg);
    padding-right: 0px;
    padding-top: 35px;
  }
`

const Label = styled.div`
  margin-top: 40px;
  display: none;
  @media (max-width: 1000px) {
    margin-top: 0px;
    padding-top: 20px;
  }
`

export default class IntroAccordian extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isGarage: true,
      isUtility: false,
      isCommerical: false,
      isBarns: false,
      isRV: false,
      isCarports: false,
    }
    this.visibleGarage = this.visibleGarage.bind(this)
    this.visibleUtility = this.visibleUtility.bind(this)
    this.visibleCommerical = this.visibleCommerical.bind(this)
    this.visibleBarns = this.visibleBarns.bind(this)
    this.visibleBarns = this.visibleBarns.bind(this)
    this.visibleRV = this.visibleRV.bind(this)
    this.visibleCarports = this.visibleCarports.bind(this)
  }
  visibleGarage() {
    this.setState({
      isGarage: true,
      isUtility: false,
      isCommerical: false,
      isBarns: false,
      isRV: false,
      isCarports: false,
    })
  }
  visibleUtility() {
    this.setState({
      isGarage: false,
      isUtility: true,
      isCommerical: false,
      isBarns: false,
      isRV: false,
      isCarports: false,
    })
  }
  visibleCommerical() {
    this.setState({
      isGarage: false,
      isUtility: false,
      isCommerical: true,
      isBarns: false,
      isRV: false,
      isCarports: false,
    })
  }
  visibleBarns() {
    this.setState({
      isGarage: false,
      isUtility: false,
      isCommerical: false,
      isBarns: true,
      isRV: false,
      isCarports: false,
    })
  }
  visibleRV() {
    this.setState({
      isGarage: false,
      isUtility: false,
      isCommerical: false,
      isBarns: false,
      isRV: true,
      isCarports: false,
    })
  }
  visibleCarports() {
    this.setState({
      isGarage: false,
      isUtility: false,
      isCommerical: false,
      isBarns: false,
      isRV: false,
      isCarports: true,
    })
  }
  render() {
    const {
      isGarage,
      isUtility,
      isCommerical,
      isBarns,
      isRV,
      isCarports,
    } = this.state
    const { data, imageData } = this.props
    let accImages = []
    data.forEach((node) => {
      imageData.forEach((item) => {
        if (node.image === item.node.title) {
          accImages.push(item.node)
        }
      })
    })
    return (
      <Wrapper>
        <LazyLoad>
          <ImageWrapper
            bg={accImages[0].fluid.src}
            width={isGarage ? "50%" : "10%"}
            height={isGarage ? "350px" : "100px"}
            onClick={() => this.visibleGarage()}
          >
            <Label display={isGarage ? "block" : "none"}>
              <PriceTip
                text={`+${data[0].amount} Products`}
                bgImg={ProductTip}
                width="160px"
                fSize="16px"
                mt="-35px"
              />
            </Label>
            <TextWrap
              width={isGarage ? "100%" : "0px"}
              height={isGarage ? "auto" : "0px"}
              opacity={isGarage ? "1" : "0"}
            >
              <h3>{data[0].name}</h3>
              <TwoColumns gridCol="auto" colGap="20px">
                <p>{data[0].description}</p>
                <Link to={data[0].link + "/"}>
                  Explore <ArrowIcon fill="white" />
                </Link>
              </TwoColumns>
            </TextWrap>
            <VerticalTextWrap display={isGarage ? "none" : "block"}>
              {data[0].name}
            </VerticalTextWrap>
          </ImageWrapper>
        </LazyLoad>
        <LazyLoad>
          <ImageWrapper
            bg={accImages[1].fluid.src}
            width={isUtility ? "50%" : "10%"}
            height={isUtility ? "350px" : "100px"}
            onClick={() => this.visibleUtility()}
          >
            <Label display={isUtility ? "block" : "none"}>
              <PriceTip
                text={`+${data[1].amount} Products`}
                bgImg={ProductTip}
                width="160px"
                fSize="16px"
                mt="-35px"
              />
            </Label>
            <TextWrap
              width={isUtility ? "100%" : "0px"}
              height={isUtility ? "auto" : "0px"}
              opacity={isUtility ? "1" : "0"}
            >
              <h3>{data[1].name}</h3>
              <TwoColumns gridCol="auto" colGap="20px">
                <p>{data[1].description}</p>
                <Link to={data[1].link + "/"}>
                  Explore <ArrowIcon fill="white" />
                </Link>
              </TwoColumns>
            </TextWrap>
            <VerticalTextWrap display={isUtility ? "none" : "block"}>
              {data[1].name}
            </VerticalTextWrap>
          </ImageWrapper>
        </LazyLoad>
        <LazyLoad>
          <ImageWrapper
            bg={accImages[2].fluid.src}
            width={isCommerical ? "50%" : "10%"}
            height={isCommerical ? "350px" : "100px"}
            onClick={() => this.visibleCommerical()}
          >
            <Label display={isCommerical ? "block" : "none"}>
              <PriceTip
                text={`+${data[2].amount} Products`}
                bgImg={ProductTip}
                width="160px"
                fSize="16px"
                mt="-35px"
              />
            </Label>
            <TextWrap
              width={isCommerical ? "100%" : "0px"}
              height={isCommerical ? "auto" : "0px"}
              opacity={isCommerical ? "1" : "0"}
            >
              <h3>{data[2].name}</h3>
              <TwoColumns gridCol="auto" colGap="20px">
                <p>{data[2].description}</p>
                <Link to={data[2].link + "/"}>
                  Explore <ArrowIcon fill="white" />
                </Link>
              </TwoColumns>
            </TextWrap>
            <VerticalTextWrap display={isCommerical ? "none" : "block"}>
              {data[2].name}
            </VerticalTextWrap>
          </ImageWrapper>
        </LazyLoad>
        <LazyLoad>
          <ImageWrapper
            bg={accImages[3].fluid.src}
            width={isBarns ? "50%" : "10%"}
            height={isBarns ? "350px" : "100px"}
            onClick={() => this.visibleBarns()}
          >
            <Label
              display={isBarns ? "block" : "none"}
              opacity={isGarage ? "1" : "0"}
            >
              <PriceTip
                text={`+${data[3].amount} Products`}
                bgImg={ProductTip}
                width="160px"
                fSize="16px"
                mt="-35px"
              />
            </Label>
            <TextWrap
              width={isBarns ? "100%" : "0px"}
              height={isBarns ? "auto" : "0px"}
              opacity={isBarns ? "1" : "0"}
            >
              <h3>{data[3].name}</h3>
              <TwoColumns gridCol="auto" colGap="20px">
                <p>{data[3].description}</p>
                <Link to={data[3].link + "/"}>
                  Explore <ArrowIcon fill="white" />
                </Link>
              </TwoColumns>
            </TextWrap>
            <VerticalTextWrap display={isBarns ? "none" : "block"}>
              {data[3].name}
            </VerticalTextWrap>
          </ImageWrapper>
        </LazyLoad>
        <LazyLoad>
          <ImageWrapper
            bg={accImages[4].fluid.src}
            width={isRV ? "50%" : "10%"}
            height={isRV ? "350px" : "100px"}
            onClick={() => this.visibleRV()}
          >
            <Label display={isRV ? "block" : "none"}>
              <PriceTip
                text={`+${data[4].amount} Products`}
                bgImg={ProductTip}
                width="160px"
                fSize="16px"
                mt="-35px"
              />
            </Label>
            <TextWrap
              width={isRV ? "100%" : "0px"}
              height={isRV ? "auto" : "0px"}
              opacity={isRV ? "1" : "0"}
            >
              <h3>{data[4].name}</h3>
              <TwoColumns gridCol="auto" colGap="20px">
                <p>{data[4].description}</p>
                <Link to={data[4].link + "/"}>
                  Explore <ArrowIcon fill="white" />
                </Link>
              </TwoColumns>
            </TextWrap>
            <VerticalTextWrap display={isRV ? "none" : "block"}>
              {data[4].name}
            </VerticalTextWrap>
          </ImageWrapper>
        </LazyLoad>
        <LazyLoad>
          <ImageWrapper
            bg={accImages[5].fluid.src}
            width={isCarports ? "50%" : "10%"}
            height={isCarports ? "350px" : "100px"}
            onClick={() => this.visibleCarports()}
          >
            <Label display={isCarports ? "block" : "none"}>
              <PriceTip
                text={`+${data[5].amount} Products`}
                bgImg={ProductTip}
                width="160px"
                fSize="16px"
                mt="-35px"
              />
            </Label>
            <TextWrap
              width={isCarports ? "100%" : "0px"}
              height={isCarports ? "auto" : "0px"}
              opacity={isCarports ? "1" : "0"}
            >
              <h3>{data[5].name}</h3>
              <TwoColumns gridCol="auto" colGap="20px">
                <p>{data[5].description}</p>
                <Link to={data[5].link + "/"}>
                  Explore <ArrowIcon fill="white" />
                </Link>
              </TwoColumns>
            </TextWrap>
            <VerticalTextWrap display={isCarports ? "none" : "block"}>
              {" "}
              {data[5].name}
            </VerticalTextWrap>
          </ImageWrapper>
        </LazyLoad>
      </Wrapper>
    )
  }
}
