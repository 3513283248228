import React from "react"
import { Link } from "gatsby"
import {
  SectionPage,
  SectionHeading,
  SectionDescription,
  ExploreMore,
  ExploreItemMore,
  Container,
} from "../Section"
import PrimaryButton from "../Button/PrimaryButton"
import SecondaryButton from "../Button/SecondaryButton"
import SliderReview from "../ReviewWdiget/slider"

const ReviewSliderWidgetCarousel = ({ sectionData }) => {
  return (
    <SectionPage
      ept="80px"
      epb="80px"
      xpt="60px"
      xpb="60px"
      pt="40px"
      pb="40px"
      bg="#fff"
    >
      <Container>
        <SectionHeading textAlign="center">{sectionData.title}</SectionHeading>
        <SectionDescription
          dangerouslySetInnerHTML={{
            __html: sectionData.description.childMarkdownRemark.html,
          }}
        />
        <SliderReview />
        <ExploreMore mt="20px" ml="-5px" mr="-5px">
          <ExploreItemMore pl="5px" pr="5px">
            <Link to="/reviews/">
              <SecondaryButton text="Load More" />
            </Link>
          </ExploreItemMore>
          <ExploreItemMore pl="5px" pr="5px">
            <a href="https://g.page/r/CYAtY7rhWYH_EAI/review" target="_blank" rel="noreferrer">
              <PrimaryButton text="Write a Review" />
            </a>
          </ExploreItemMore>
        </ExploreMore>
      </Container>
    </SectionPage>
  )
}

export default ReviewSliderWidgetCarousel
